/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  NavLink,
  useLocation,
} from 'react-router-dom'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { Global } from '@emotion/core'
//import { TiHome as HomeIcon } from 'react-icons/ti'
//import { TiStarFullOutline as FeaturesIcon } from 'react-icons/ti'
//import { TiPhone as ContactIcon } from 'react-icons/ti'
import { FaPen as PenIcon } from 'react-icons/fa'

import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'

import { ReactComponent as HomeIcon } from './upplysa.svg'
import { ReactComponent as PortfolioIcon } from './referenser.svg'
import { ReactComponent as ContactIcon } from './phone.svg'

import { ReactComponent as BankIdIcon } from './bankid.svg'
import { ReactComponent as ContactsIcon } from './contacts.svg'
import { ReactComponent as SnapchatIcon } from './snapchat.svg'

import { ReactComponent as FeaturesIcon } from './funktioner.svg'
import { ReactComponent as BigSnapchat } from './big-snapchat.svg'

const FixedTabBar = styled.div({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#fff',
  height: 'calc(60px + env(safe-area-inset-bottom))',
  display: 'flex',
  flexDirection: 'row',
  boxShadow: '0px 0px 5px 2px #eee',
  // https://css-tricks.com/the-notch-and-css/
  paddingBottom: 'env(safe-area-inset-bottom)',
})

const BoxTabBar = styled.div({
  backgroundColor: '#fff',
  flexShrink: 0,
  height: 60,
  display: 'flex',
  flexDirection: 'row',
  boxShadow: '0px -0.5px 3px #00000019',
  zIndex: 1,
})

const TabIcon = styled.div({})
const TabText = styled.div({ fontSize: 9, fontWeight: 600, marginTop: 3 })

const FullContent = styled.div({
  paddingBottom: 'calc(60px + env(safe-area-inset-bottom))',
})

const RoundedBox = styled.div({
  borderRadius: 8,
  margin: 16,
  padding: 16,
  backgroundColor: 'white',
  boxShadow: '0 3px 6px #00000010',
})

const Header1 = styled.h1({
  marginLeft: 14,
  marginTop: 36,
  marginBottom: 18,
  fontSize: 29,
  fontWeight: 800,
})
const Header2 = styled.h2({
  fontSize: 15,
  marginLeft: 31,
  marginTop: 27,
  fontWeight: 800,
})

const menu = [
  { icon: <HomeIcon />, text: 'Upplysa', to: '' },
  { icon: <FeaturesIcon />, text: 'Funktioner', to: 'features' },
  { icon: <PortfolioIcon />, text: 'Referenser', to: 'portfolio' },
  { icon: <ContactIcon />, text: 'Kontakta', to: 'contact' },
]

const GrowingContainer = styled.div()

// Not used intentionally
// eslint-disable-next-line
const OrganicLayout = {
  Container: GrowingContainer,
  TabBar: FixedTabBar,
  Content: FullContent,
  htmlCss: {},
}

const green = '#009454'

const FullFlexLayout = {
  Container: (props => {
    React.useLayoutEffect(() => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      window.addEventListener('resize', () => {
        setTimeout(() => {})
        console.log('onresize')
        let vh = window.innerHeight * 0.01

        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })
    })
    return (
      <div
        css={css`
          height: 100vh;
          height: calc(var(--vh, 1vh) * 100);
          display: flex;
          flex-direction: column;
        `}
        {...props}
      />
    )
  }) as React.FC,
  TabBar: BoxTabBar,
  Content: styled.div({
    overflow: 'scroll',
    flexGrow: 1,
    // https://stackoverflow.com/questions/10238084/ios-safari-how-to-disable-overscroll-but-allow-scrollable-divs-to-scroll-norma
    //overscrollBehavior: 'contain',
  }),
  htmlCss: {
    // https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/
    position: 'fixed' as const,
    width: '100%',
    overflow: 'hidden',
  },
}

const Layout = FullFlexLayout

const Functions: React.FC = props => {
  const history = useHistory()
  const {
    selectContact,
    selectedContact,
    selectContactSupported,
  } = useSelectContact()
  return (
    <div {...props}>
      <Header1>Funktioner</Header1>
      <RoundedBox
        css={{
          lineHeight: '18px',
          display: 'flex',
          alignItems: 'center',
          color: '#B2B2B2',
        }}
      >
        <div css={{ marginLeft: 7, marginRight: 16 }}>
          <PenIcon size={24} color={'#B2B2B2'} />
        </div>
        <div css={{ fontWeight: 600 }}>
          Inmatningar
          <br />
          <span css={{ color: '#B2B2B2', fontSize: 12 }}>
            Text, bilder, datum, m.m.
          </span>
        </div>
      </RoundedBox>
      <Header2>Appar i webbläsaren</Header2>
      <RoundedBox
        css={{ lineHeight: '18px', display: 'flex', alignItems: 'center' }}
        onClick={() => {
          history.push('/snapchat')
        }}
      >
        <div css={{ marginLeft: 7, marginRight: 16 }}>
          <SnapchatIcon />
        </div>
        <div>
          Snapchat-kopia
          <br />
          <span css={{ color: '#B2B2B2', fontSize: 12 }}>
            Direkt i webbläsaren
          </span>
        </div>
      </RoundedBox>
      <Header2>Andra appar & tjänster</Header2>
      <RoundedBox css={{ lineHeight: '18px', color: '#B2B2B2' }}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div css={{ marginLeft: 7, marginRight: 16 }}>
            <BankIdIcon css={{ filter: 'grayscale(1) brightness(160%)' }} />
          </div>
          <div>
            Öppna BankID
            <br />
            <span css={{ color: '#B2B2B2', fontSize: 12 }}>
              Signera, Swisha, m.m.
            </span>
          </div>
        </div>
        <hr
          css={{
            marginRight: -16,
            marginLeft: 48,
            height: 1,
            backgroundColor: '#F2F2F2',
            border: 'none',
          }}
        />
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            color: selectContactSupported ? 'black' : '#B2B2B2',
          }}
          onClick={selectContact}
        >
          <div css={{ marginLeft: 7, marginRight: 16, width: 25 }}>
            <ContactsIcon fill={selectContactSupported ? green : '#B2B2B2'} />
          </div>
          <div>
            Lägg till kontakt
            <br />
            <span css={{ color: '#B2B2B2', fontSize: 12 }}>
              Lägg till kontaktuppgifter
            </span>
          </div>
        </div>
        {selectedContact ? (
          <div
            css={{
              marginTop: 10,
              color: '#111',
              span: { color: '#999', marginTop: 5, display: 'inline-block' },
              lineHeight: 1.3,
            }}
          >
            {selectedContact.name.length > 0 && (
              <React.Fragment>
                <span>Namn:</span>
                <br />
                {selectedContact.name.join(' ')}
                <br />
              </React.Fragment>
            )}
            {selectedContact.email.length > 0 && (
              <React.Fragment>
                <span>E-mail:</span> <br />
                {selectedContact.email.join(' ')}
                <br />
              </React.Fragment>
            )}
            {selectedContact.tel.length > 0 && (
              <React.Fragment>
                <span>T.nr:</span>
                <br />
                {selectedContact.tel.join(' ')}
                <br />
              </React.Fragment>
            )}
          </div>
        ) : null}
      </RoundedBox>
      <Header2>Utfyllnad för scroll</Header2>

      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(ix => (
        <RoundedBox css={{ lineHeight: '18px' }} key={ix}>
          Inmatningar
          <br />
          <span css={{ color: '#B2B2B2', fontSize: 12 }}>
            Text, bilder, datum, m.m.
          </span>
        </RoundedBox>
      ))}
    </div>
  )
}

type Contact = {
  name: string[]
  email: string[]
  tel: string[]
}

function useSelectContact() {
  const selectContactSupported =
    'contacts' in navigator && 'ContactsManager' in window

  const [selectedContact, setSelectedContact] = React.useState<null | Contact>(
    null,
  )
  const [selectionError, setSelectionError] = React.useState<null | string>(
    null,
  )

  const selectContact = async () => {
    const props = ['name', 'email', 'tel']

    try {
      const contacts = await (navigator as any).contacts.select(props)
      console.log(contacts[0])
      setSelectedContact(contacts[0])
    } catch (ex) {
      setSelectionError(ex)
    }
  }

  return {
    selectContactSupported,
    selectContact,
    selectionError,
    selectedContact,
  }
}

const Snapchat: React.FC = () => {
  const history = useHistory()
  const [showCamera, setShowCamera] = React.useState(false)
  return (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      {showCamera ? (
        <div
          css={{
            minHeight: 415 + 16 * 3 + 20,
            '.react-html5-camera-photo': {},
          }}
        >
          <Camera
            css={{ color: 'red' }}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isImageMirror={false}
          />
        </div>
      ) : (
        <BigSnapchat
          css={{
            marginTop: 140,
            marginBottom: 118,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!showCamera && (
        <button
          onClick={() => setShowCamera(true)}
          css={{
            backgroundColor: green,
            color: 'white',
            margin: '16px 32px 0',
            padding: 17,
            fontSize: 17,
            fontWeight: 800,
            border: 0,
            borderRadius: 10,
            outline: 'none',
          }}
        >
          Aktivera kameran
        </button>
      )}
      <button
        onClick={() => history.goBack()}
        css={{
          backgroundColor: 'white',
          color: green,
          margin: '16px 50px 16px',
          padding: 17,
          fontSize: 17,
          fontWeight: 800,
          border: 0,
          borderRadius: 10,
          outline: 'none',
        }}
      >
        Gå tillbaka
      </button>
    </div>
  )
}

const App2: React.FC = () => (
  <Router>
    <App />
  </Router>
)

const App: React.FC = () => {
  const location = useLocation()

  const TabBar = (
    <Layout.TabBar>
      {menu.map(({ icon, text, to }, ix) => {
        return (
          <NavLink
            key={to}
            exact
            activeClassName="selected"
            replace
            css={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textDecoration: 'none',
              outline: 'none',
              color: '#999',
              WebkitTapHighlightColor: 'transparent',
              '&.selected': {
                color: green,
              },
            }}
            to={'/' + to}
          >
            <TabIcon>{icon}</TabIcon>
            <TabText>{text}</TabText>
          </NavLink>
        )
      })}
    </Layout.TabBar>
  )

  return (
    <Layout.Container
      css={{
        '.slide-enter': {
          position: 'absolute',
          width: '100%',
          transform: 'translateX(100px)',
        },
        '.slide-enter-active': {
          position: 'absolute',
          width: '100%',
          transform: 'translateX(0px)',
          transition: 'transform 200ms',
        },
        '.slide-exit': {
          position: 'absolute',
          opacity: 1,
          width: '100%',
          transform: 'translateX(0px)',
        },
        '.slide-exit-active': {
          position: 'absolute',
          width: '100%',
          opacity: 0,
          transform: 'translateX(-100px)',
          transition: 'transform 300ms, opacity 200ms',
        },
      }}
    >
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css?family=Montserrat:500,600,700,800&display=swap');
        `}
      ></Global>
      <Global
        styles={{
          html: {
            backgroundColor: '#F2F2F2',

            fontFamily: "'Montserrat', sans-serif",
            fontSize: 16,

            userSelect: 'none',

            ...Layout.htmlCss,
          },
        }}
      ></Global>
      <Layout.Content>
        <TransitionGroup>
          <CSSTransition timeout={200} classNames="slide" key={location.key}>
            <Switch location={location}>
              <Route exact path="/">
                <Header1>Upplysa</Header1>
              </Route>
              <Route path="/features">
                <Functions />
              </Route>
              <Route exact path="/snapchat">
                <Snapchat />
              </Route>
              <Route exact path="/portfolio">
                <div>
                  <Header1>Tidigare projekt</Header1>
                  <RoundedBox
                    css={{
                      position: 'relative',
                      '&:before': {
                        position: 'absolute',
                        bottom: -6,
                        left: 9,
                        zIndex: -1,
                        display: 'block',
                        content: "''",
                        backgroundColor: '#FAFAFA',
                        borderRadius: '5px',
                        boxShadow: '0 3px 6px #00000010',
                        height: 20,
                        width: 'calc(100% - 20px)',
                      },
                      minHeight: 100,
                    }}
                  >
                    Hej
                  </RoundedBox>
                </div>
              </Route>
              <Route exact path="/contact">
                <Header1>Kontakt</Header1>
              </Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </Layout.Content>
      <Route exact path={['/', '/features', '/portfolio', '/contact']}>
        {TabBar}
      </Route>
    </Layout.Container>
  )
}

export default App2
